import React from "react";
import { Redirect } from "react-router-dom";

const Xd = () => {
  window.location.href = "/restware";

  return null;
};

export default Xd;
