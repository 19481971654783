import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import React from "react";
import "yet-another-react-lightbox/styles.css";
import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/plugins/counter.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import { Link } from "react-router-dom";
import Menue from "./Menue";

const photos = [
  {
    src: "./pictures/more/more_1.JPG",
    width: 1000,
    height: 750,
  },
  {
    src: "./pictures/more/more_10.JPG",
    width: 1000,
    height: 666,
  },
  {
    src: "./pictures/more/more_11.JPG",
    width: 856,
    height: 1000,
  },
  {
    src: "./pictures/more/more_12.JPG",
    width: 747,
    height: 1000,
  },
  {
    src: "./pictures/more/more_13.JPG",
    width: 1000,
    height: 666,
  },
  {
    src: "./pictures/more/more_14.JPG",
    width: 1000,
    height: 788,
  },
  {
    src: "./pictures/more/more_15.JPG",
    width: 666,
    height: 1000,
  },
  {
    src: "./pictures/more/more_16.JPG",
    width: 1000,
    height: 666,
  },
  {
    src: "./pictures/more/more_17.JPG",
    width: 1000,
    height: 617,
  },
  {
    src: "./pictures/more/more_18.JPG",
    width: 1000,
    height: 666,
  },
  {
    src: "./pictures/more/more_19.JPG",
    width: 1000,
    height: 667,
  },
  {
    src: "./pictures/more/more_2.JPG",
    width: 1000,
    height: 666,
  },
  {
    src: "./pictures/more/more_20.JPG",
    width: 1000,
    height: 667,
  },
  {
    src: "./pictures/more/more_21.JPG",
    width: 1000,
    height: 750,
  },
  {
    src: "./pictures/more/more_22.JPG",
    width: 731,
    height: 1000,
  },
  {
    src: "./pictures/more/more_23.JPG",
    width: 1000,
    height: 667,
  },
  {
    src: "./pictures/more/more_24.JPG",
    width: 1000,
    height: 750,
  },
  {
    src: "./pictures/more/more_25.JPG",
    width: 1000,
    height: 750,
  },
  {
    src: "./pictures/more/more_26.JPG",
    width: 1000,
    height: 750,
  },
  {
    src: "./pictures/more/more_27.JPG",
    width: 1000,
    height: 750,
  },
  {
    src: "./pictures/more/more_28.JPG",
    width: 1000,
    height: 750,
  },
  {
    src: "./pictures/more/more_29.JPG",
    width: 1000,
    height: 750,
  },
  {
    src: "./pictures/more/more_3.JPG",
    width: 666,
    height: 1000,
  },
  {
    src: "./pictures/more/more_30.JPG",
    width: 1000,
    height: 750,
  },
  {
    src: "./pictures/more/more_31.JPG",
    width: 1000,
    height: 750,
  },
  {
    src: "./pictures/more/more_32.JPG",
    width: 1000,
    height: 750,
  },
  {
    src: "./pictures/more/more_33.JPG",
    width: 1000,
    height: 750,
  },
  {
    src: "./pictures/more/more_34.JPG",
    width: 1000,
    height: 750,
  },
  {
    src: "./pictures/more/more_35.JPG",
    width: 1000,
    height: 665,
  },
  {
    src: "./pictures/more/more_36.JPG",
    width: 1000,
    height: 750,
  },
  {
    src: "./pictures/more/more_37.JPG",
    width: 1000,
    height: 750,
  },
  {
    src: "./pictures/more/more_38.JPG",
    width: 1000,
    height: 750,
  },
  {
    src: "./pictures/more/more_39.JPG",
    width: 1000,
    height: 653,
  },
  {
    src: "./pictures/more/more_4.JPG",
    width: 1000,
    height: 750,
  },
  {
    src: "./pictures/more/more_40.JPG",
    width: 1000,
    height: 750,
  },
  {
    src: "./pictures/more/more_41.JPG",
    width: 1000,
    height: 750,
  },
  {
    src: "./pictures/more/more_42.JPG",
    width: 1000,
    height: 665,
  },
  {
    src: "./pictures/more/more_43.JPG",
    width: 1000,
    height: 750,
  },
  {
    src: "./pictures/more/more_44.JPG",
    width: 1000,
    height: 665,
  },
  {
    src: "./pictures/more/more_45.JPG",
    width: 1000,
    height: 665,
  },
  {
    src: "./pictures/more/more_46.JPG",
    width: 1000,
    height: 665,
  },
  {
    src: "./pictures/more/more_47.JPG",
    width: 1000,
    height: 667,
  },
  {
    src: "./pictures/more/more_48.JPG",
    width: 1000,
    height: 667,
  },
  {
    src: "./pictures/more/more_49.JPG",
    width: 1000,
    height: 750,
  },
  {
    src: "./pictures/more/more_5.JPG",
    width: 1000,
    height: 750,
  },
  {
    src: "./pictures/more/more_50.JPG",
    width: 1000,
    height: 750,
  },
  {
    src: "./pictures/more/more_51.JPG",
    width: 1000,
    height: 667,
  },
  {
    src: "./pictures/more/more_52.JPG",
    width: 1000,
    height: 1000,
  },
  {
    src: "./pictures/more/more_53.JPG",
    width: 1000,
    height: 1000,
  },
  {
    src: "./pictures/more/more_54.JPG",
    width: 1000,
    height: 665,
  },
  {
    src: "./pictures/more/more_6.JPG",
    width: 1000,
    height: 750,
  },
  {
    src: "./pictures/more/more_7.JPG",
    width: 1000,
    height: 750,
  },
  {
    src: "./pictures/more/more_8.JPG",
    width: 1000,
    height: 750,
  },
  {
    src: "./pictures/more/more_9.JPG",
    width: 1000,
    height: 750,
  },
];

export default function More({ setShowMore }) {
  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState(0);

  const fullscreenRef = React.useRef(null);

  return (
    <div>
      <Menue />
      <h1>Ein Einblick in unseren Laden</h1>
      <div>Wir führen folgende Marken: </div>
      <div className="marken">
        Aquadeco, Aquael, AquaNova, Blau, Colombo, Dennerle, DutchSelect, JBL,
        Marina, Masterline, Sera, SuperFish, Tropica, VIV ...
      </div>
      <div style={{ padding: "25px" }}>
        <PhotoAlbum
          layout="rows"
          photos={photos}
          onClick={({ index }) => {
            setIndex(index);
            setOpen(true);
          }}
        />
        <Lightbox
          index={index}
          open={open}
          close={() => setOpen(false)}
          slides={photos}
          plugins={[Counter, Fullscreen]}
          counter={{ container: { style: { top: "unset", bottom: 0 } } }}
          fullscreen={{ ref: fullscreenRef }}
          on={{
            click: () => fullscreenRef.current?.enter(),
          }}
        />
      </div>
      <div style={{ textDecoration: "underline" }}>
        <Link to="/" className="white">
          zurück &#x2B90;
        </Link>
      </div>
    </div>
  );
}
