import React from "react";
import { Link } from "react-router-dom";

export default function Cookie({ setCookie }) {
  return (
    <div className="cookiebanner">
      <div className="">
        <p>
          Diese Website verwendet Cookies nähere Informationen dazu und zu Ihren
          Rechten als Benutzer finden Sie in unserer Datenschutzerklärung.
        </p>
      </div>

      <div
        className=""
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          placeContent: "center",
          gap: "5px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "300px",
          }}
        >
          <label htmlFor="a" className="">
            Alle Cookies akzeptieren
          </label>
          <input
            type="checkbox"
            id="alleCookies"
            value="Alle Cookies akzeptieren"
            name="a"
            onClick={() => {
              setCookie(true);
              window.localStorage.setItem("cookie", true);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "300px",
          }}
        >
          <label htmlFor="b" className="">
            Notwendige Cookies akzeptieren{" "}
          </label>
          <input
            type="checkbox"
            id="notwendigeCookies"
            value="Notwendige Cookies akzeptieren"
            name="b"
            onClick={() => {
              setCookie(true);
              window.localStorage.setItem("cookie", true);
            }}
          />
        </div>
      </div>

      <div className="cookie-links" style={{ marginTop: "5px" }}>
        <Link to="/datenschutz">Datenschutzerklärung</Link>
        <br></br>
        <Link to="/impressum">Impressum</Link>
        <br></br>
      </div>
    </div>
  );
}
