import Lightbox from "yet-another-react-lightbox";

import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import React from "react";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import "yet-another-react-lightbox/plugins/counter.css";

import PhotoAlbum from "react-photo-album";

import "yet-another-react-lightbox/styles.css";

import "yet-another-react-lightbox/plugins/counter.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";

const photosG = [
  {
    src: "./pictures/restware/438087336_760303902921833_5567861528157573126_n.jpg",
    width: 755,
    height: 710,
  },
  {
    src: "./pictures/restware/438096506_760303912921832_2756728092986491788_n.jpg",
    width: 1000,
    height: 750,
  },
  {
    src: "./pictures/restware/Restware_19.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_2.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_20.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_21.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_23.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_3.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_35.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_36.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_37.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_38.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_39.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_4.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_40.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_41.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_42.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_43.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_44.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_499.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_5.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_500.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_501.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_502.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_503.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_504.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_505.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_506.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_507.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_508.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_509.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_51.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_510.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_511.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_512.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_513.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_514.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_515.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_516.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_54.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_56.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_6.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_7.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_70.jpg",
    width: 2000,
    height: 1500,
  },
  {
    src: "./pictures/restware/Restware_75.jpg",
    width: 2000,
    height: 1500,
  },
];

const photos = [
  {
    src: "./pictures/restware/klein/resized_438087336_760303902921833_5567861528157573126_n.jpg",
    width: 500,
    height: 470,
  },
  {
    src: "./pictures/restware/klein/resized_438096506_760303912921832_2756728092986491788_n.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_19.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_2.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_20.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_21.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_23.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_3.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_35.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_36.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_37.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_38.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_39.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_4.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_40.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_41.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_42.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_43.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_44.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_499.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_5.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_500.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_501.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_502.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_503.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_504.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_505.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_506.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_507.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_508.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_509.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_51.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_510.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_511.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_512.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_513.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_514.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_515.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_516.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_54.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_56.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_6.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_7.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_70.jpg",
    width: 500,
    height: 375,
  },
  {
    src: "./pictures/restware/klein/resized_Restware_75.jpg",
    width: 500,
    height: 375,
  },
];

export default function SaleInventory() {
  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState(0);

  const fullscreenRef = React.useRef(null);

  return (
    <div style={{ padding: "25px" }}>
      <PhotoAlbum
        layout="rows"
        photos={photos}
        onClick={({ index }) => {
          setIndex(index);
          setOpen(true);
        }}
      />
      <Lightbox
        index={index}
        open={open}
        close={() => setOpen(false)}
        slides={photosG}
        plugins={[Counter, Fullscreen]}
        counter={{ container: { style: { top: "unset", bottom: 0 } } }}
        fullscreen={{ ref: fullscreenRef }}
        on={{
          click: () => fullscreenRef.current?.enter(),
        }}
      />
    </div>
  );
}
