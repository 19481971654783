const reviews = [
    {
        "name": "Aquaman",
        "review": "Ein tolles Fachgesch\u00e4ft f\u00fcr Aqua Freunde kann man nur empfehlen . Weiter so.",
        "stars": "5"
    },
    {
        "name": "Detlef",
        "review": "Eine tolle Auswahl an sch\u00f6nen Dekoartikeln f\u00fcr den ambitionierte Scaper",
        "stars": "5"
    },
    {
        "name": "Fabian",
        "review": "Sehr sch\u00f6ner Laden mit liebe zum Detail. Super Auswahl an Pflanzen, Steinen und Wurzeln. Die Preise waren auch sehr gut. Hier f\u00fchlt man sich gut aufgehoben und kommt gerne wieder.",
        "stars": "5"
    },
    {
        "name": "Peter",
        "review": "Sehr gro\u00dfe Auswahl an Steinen, Wurzeln, \u00c4sten usw. Alles ordentlich sortiert. Sch\u00f6ner \u00fcbersichtlicher Laden und Preise sind angemessen. Eigent\u00fcmer sind bei Fragen freundlich und hilfsbereit. Komme gerne wieder.",
        "stars": "5"
    },
    {
        "name": "V.",
        "review": "Hier findet man alles und reichlich um sein Aquarium einzurichten. Wurzeln, Steine usw. Sehr Hochwertiges Frost und Lebendfutter gibt es auch. Inhaber sind sehr freundlich und hilfsbereit. Ich kann dieses Gesch\u00e4ft jedem empfehlen. Ich selber werde in Zukunft \u00f6fters dort einkaufen.",
        "stars": "5"
    },
    {
        "name": "Basti",
        "review": "Kleiner, aber gut sortierter Laden. Sehr freundliche Beratung. Bin sehr froh \u00fcber die zuf\u00e4llige Entdeckung. War bereits 2-mal dort und komme definitiv wieder.",
        "stars": "5"
    },
    {
        "name": "Linda",
        "review": "Was f\u00fcr eine super Auswahl an Pflanzen, Steinen und Wurzeln! Dazu kommt eine kompetente und freundliche Beratung vor Ort. Wir waren nicht das letzte Mal hier.",
        "stars": "5"
    },
    {
        "name": "Gerd",
        "review": "Sehr freundlich und gute Beratung... Werde wieder kommen...",
        "stars": "5"
    },
    {
        "name": "oldtimer-tractoren",
        "review": "Wir waren heute das erste mal im Laden und sind positiv \u00fcberrascht :) wir haben auf Anhieb alles gefunden , um unser neues Aquarium einzurichten . Sowohl die Produkte als auch der Kundenservice sind super . Die Preise sind vollkommen ok \ud83d\udc4d Hier gibt es nichts zu meckern und Wir freuen Uns auf den n\u00e4chsten Besuch. \u2026",
        "stars": "5"
    },
    {
        "name": "Bbone",
        "review": "Wir waren heute das erste Mal hier und wurden nicht entt\u00e4uscht. Super Laden f\u00fcr H\u00f6lzer, Wurzeln, Steine, Pflanzen, Deko und Aquarienzubeh\u00f6r. Sehr nette und freundliche Bedienung.War bestimmt nicht unser letzter Besuch.Daumen hoch!",
        "stars": "5"
    },
    {
        "name": "GG",
        "review": "Wir haben heute ca. 100 km Anreise auf uns genommen um eins unserer Becken mit einem neuen Hardscape auszustatten. Wie nicht anders erwartet wurden wir f\u00fcndig. Wir wurden sehr nett empfangen und man hat sich f\u00fcr uns Zeit genommen . Wir sind rundum zufrieden und w\u00fcrden jederzeit die Anreise wieder in kauf nehmen. \ud83d\udc4c \u2026",
        "stars": "5"
    },
    {
        "name": "Oliver",
        "review": "Super sortierter Laden. Man findet alles was das Herz begehrt. Kompetentes und freundliches Personal. In kurzer Zeit zum Stammkunden geworden. Weiter so :-)",
        "stars": "5"
    },
    {
        "name": "Al",
        "review": "Au\u00dferorentlich gut sortierter Laden im Bereich Hardscape und Pflanzen. Zwischen der holl\u00e4ndischen Grenze am Niederrhein und D\u00fcsseldorf f\u00fcr Aquascaping m.E. das Ma\u00df aller Dinge. Wer sich ein Becken zulegen und/oder ausstatten m\u00f6chte, sollte hier auf jedenfall einmal vorbeischauen.",
        "stars": "5"
    },
    {
        "name": "Romy",
        "review": "Spitzen Beratung. Hier nimmt sich der Chef noch richtig Zeit f\u00fcr Seine Kunden, sogar wenn richtig viel los ist im Gesch\u00e4ft. Sehr sympathisch. Vielen Dank",
        "stars": "5"
    },
    {
        "name": "Denise",
        "review": "Sehr sch\u00f6nes Gesch\u00e4ft mit Herzblut eingerichtet. Immer top beraten worden und immer freundlich empfangen worden.",
        "stars": "5"
    },
    {
        "name": "Jessica",
        "review": "Kleiner versteckter Laden mit einer tollen Auswahl von Naturmaterialien, Steinen und Wurzeln. Wer wie ich das Hardscape lieber vorher sehen m\u00f6chte und eine gro\u00dfe Auswahl sucht ist dort gut aufgehoben.",
        "stars": "5"
    },
    {
        "name": "M",
        "review": "Ein Laden mit top Auswahl an Hardscape. Sehr gepflegtes Ambiente. Sehr nette und freundliche Gesch\u00e4ftsinhaber. Hier schl\u00e4gt das Herz eines jeden Aquascapers h\u00f6her. Auch eine weite Anfahrt lohnt sich. Wir waren nicht das letzte mal da.",
        "stars": "5"
    },
    {
        "name": "B",
        "review": "Kleiner Laden. Sehr viel Auswahl. Perfekt f\u00fcr Aquascaper. Sehr freundlicher Inhaber. Top Empfehlung. (Geheimtipp)",
        "stars": "5"
    },
    {
        "name": "Christoph",
        "review": "Sehr sch\u00f6ner Laden, grosses Sortiment an Wurzel, Aquarien sowie Zubeh\u00f6r. Waren mit der Beratung ebenfalls sehr zufrieden. Gro\u00dfer Parkplatz direkt vorm Laden.Wir werden wieder kommen!",
        "stars": "5"
    },
    {
        "name": "Marc",
        "review": "Top Produkte. Sehr sch\u00f6n eingerichtet und ein sehr hilfsbereiter Verk\u00e4ufer. Kann ich nur empfehlen.",
        "stars": "5"
    },
    {
        "name": "Dirk",
        "review": "Sehr sch\u00f6ner Laden. Super Beratung und Freundlich. Werde bei dir meine n\u00e4chste Einrichtung kaufen.",
        "stars": "5"
    },
    {
        "name": "Markus",
        "review": "Jeder der Interesse an Aquaristik hat, sollte hier meiner Meinung nach unbedingt mal vorbeischauen. Das Gesamtkonzept ist einfach stimmig. Das Sortiment an Wurzeln, Steinen und sonstigem Zubeh\u00f6r ist klasse. Man bekommt sofort Lust ein Aquarium einzurichten. Die Beratung war super, sehr angenehm und freundlich.",
        "stars": "5"
    },
    {
        "name": "Simone",
        "review": "Tolle Auswahl an Hardscape, Technik, Zubeh\u00f6r und Futter. Wir wurden sehr freundlich empfangen und waren bestimmt nicht zum letzten Mal hier.",
        "stars": "5"
    },
    {
        "name": "Christian",
        "review": "Top laden sehr netter Umgang mit den Kunden gerne wieder.. tolle Auswahl an Produkten. \u2026",
        "stars": "5"
    },
    {
        "name": "B\u00e4rbel",
        "review": "Wir waren heute zum ersten Mal da ,und sind von der Auswahl begeistert ,tolle Wurzeln ,B\u00e4umchen und Steine ,insgesamt ein sehr sch\u00f6nes Sortiment ,unser neuer Laden f\u00fcr s\u00fc\u00dfwasser",
        "stars": "5"
    },
    {
        "name": "Claudia",
        "review": "Kleines , leicht versteckt liegendes Fachgesch\u00e4ft , gute Auswahl und moderate Preise .",
        "stars": "4"
    },
    {
        "name": "Markus",
        "review": "Super freundlich und hilfsbereit. Eine sehr sch\u00f6ne und kreative Auswahl an Dekoration Objekten, Pflanzen und Tiere.",
        "stars": "5"
    },
    {
        "name": "Sabine",
        "review": "So netter, freundlicher und hilfsbereite Inhaber...ich fahre sehr gern dorthin",
        "stars": "5"
    },
    {
        "name": "Dietmar",
        "review": "Sehr sch\u00f6ner Laden, gute fachkundige Beratung. Sehr sch\u00f6ne Ware und Futtersorten.",
        "stars": "5"
    },
    {
        "name": "Marcus",
        "review": "Erste Adresse f\u00fcr die Einrichtung eines Aquariums. Hier gibt es alles in vielen Ausf\u00fchrungen. Super Auswahl.",
        "stars": "5"
    },
    {
        "name": "sascha",
        "review": "Klein aber sehr \u00fcbersichtlich.gut sortiert.sehr hilfsbereit.preis Leistung ist gut.wir kommen gerne wieder.",
        "stars": "5"
    },
    {
        "name": "Lobo",
        "review": "Sehr sch\u00f6ner und leidenschaftlich gef\u00fchrter Laden tolle Auswahl",
        "stars": "5"
    },
    {
        "name": "noel",
        "review": "Toller Laden, Super beratung und viel auswahl",
        "stars": "5"
    },
    {
        "name": "giuseppe",
        "review": "Top Laden sch\u00f6nes gro\u00dfes Sortiment gute Beratung und freundlich.",
        "stars": "5"
    },
    {
        "name": "Richard-Georg",
        "review": "Der Ladenbesitzer ist sehr zuvorkommend und sehr freundlich, mit gro\u00dfer Fachkompetenz!",
        "stars": "5"
    },
    {
        "name": "Patrick",
        "review": "Freundliche und kompetente Beratung",
        "stars": "5"
    },
    {
        "name": "Detlef",
        "review": "Sehr kompetente und freundliche Beratung.",
        "stars": "5"
    },
    {
        "name": "Anja",
        "review": "Super freundlich. Immer wieder gerne",
        "stars": "5"
    },
    {
        "name": "Jan-Aaron",
        "review": "Cooler Laden mit allem was das Aquascaping Herz begehrt!",
        "stars": "5"
    },
    {
        "name": "Theo",
        "review": "Haben doch eine sehr gro\u00dfe Auswahl!!",
        "stars": "5"
    },
    {
        "name": "Achim",
        "review": "Top Gesch\u00e4ft und super freundlich",
        "stars": "5"
    },
    {
        "name": "Nadine",
        "review": "Super nett. Tolle Auswahl.",
        "stars": "5"
    },
    {
        "name": "Dominik",
        "review": "Alles bestens immer wieder gern",
        "stars": "5"
    },
    {
        "name": "M",
        "review": "Sehr sch\u00f6ner Laden.",
        "stars": "5"
    },
    {
        "name": "Simon",
        "review": "Perfekt f\u00fcr Aquascaping. Freundliches Personal.",
        "stars": "5"
    },
    {
        "name": "Artur",
        "review": "Zuper laden !!!!!!",
        "stars": "5"
    },
    {
        "name": "Ralf",
        "review": "Gro\u00dfe Auswahl an Hardscape-Materialien.",
        "stars": "5"
    },
    {
        "name": "David",
        "review": "Interessant ein wenig klein",
        "stars": "4"
    },
    {
        "name": "Wendy",
        "review": "Keine Bewertung",
        "stars": "5"
    },
    {
        "name": "Samed",
        "review": "Keine Bewertung",
        "stars": "5"
    },
    {
        "name": "Henrik",
        "review": "Keine Bewertung",
        "stars": "5"
    },
    {
        "name": "Tanja",
        "review": "Keine Bewertung",
        "stars": "5"
    },
    {
        "name": "Alexander",
        "review": "Keine Bewertung",
        "stars": "5"
    },
    {
        "name": "Christian",
        "review": "Keine Bewertung",
        "stars": "5"
    },
    {
        "name": "Dennis",
        "review": "Keine Bewertung",
        "stars": "5"
    },
    {
        "name": "Verenaa25",
        "review": "Keine Bewertung",
        "stars": "5"
    },
    {
        "name": "Eva",
        "review": "Keine Bewertung",
        "stars": "3"
    },
    {
        "name": "Alex",
        "review": "Keine Bewertung",
        "stars": "5"
    },
    {
        "name": "Wilco",
        "review": "Keine Bewertung",
        "stars": "5"
    }
]

export default reviews.filter(review => review.review !== "Keine Bewertung");