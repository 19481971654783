import React from "react";
import { Link } from "react-router-dom";
import Menue from "./Menue";
const steine = {
  steine: [
    {
      name: "Mini-Landschaft",
      name2: "premium dark",
      preis: 0,
      einheit: "kg",
      bild: "minilandschaftdark.jpg",
      fav: false,
      mehrFotos: [
        "4617050Flyer_q.jpg",
        "188191Huhn.png",
        "1246520Sera reptil aqua biotop.jpg",
        "821695SF Scaper.jpg",
        "188191Huhn.png",
      ],
    },
    {
      name: "Mini-Landschaft",
      name2: "premium",
      preis: 0,
      einheit: "kg",
      bild: "mini.jpg",
      fav: false,
    },
    {
      name: "Drachenstein",
      name2: "Ohko",
      preis: 0,
      einheit: "kg",
      bild: "drachenstein.jpg",
      fav: false,
    },
    {
      name: "Asiat. Lochgestein",
      name2: "kugelgestrahlt, weiß",
      preis: 0,
      einheit: "4 /kg",
      bild: "lochgestein.jpg",
      fav: false,
    },
    {
      name: "Aquariumfels",
      name2: "natur",
      preis: 0,
      einheit: "5,50 /kg",
      bild: "aquariumfels.jpg",
      fav: false,
    },
    {
      name: "Regenbogenkugeln",
      name2: "bunt",
      preis: 0,
      einheit: "Stück",
      bild: "regenbogenkugeln.jpg",
      fav: false,
    },
    {
      name: "Premium-Lava",
      name2: "tief schwarz",
      preis: 0,
      einheit: "5,50 /kg",
      bild: "premiumlava.jpg",
      fav: false,
      marke: "Natur",
      eancode: "1234",
      artikelnummer: "1234",
      "ausverkauf?": true,
      angebot: true,
    },
    {
      name: "Schwarzer Findling",
      name2: "Flussstein",
      preis: 0,
      einheit: "4 /kg",
      bild: "schwarzerfindling.jpg",
      fav: false,
      neu: true,
    },
    {
      name: "Lava-Lochgestein",
      name2: "2-Loch",
      preis: 0,
      einheit: "8,50 /Stück",
      bild: "lava2loch.jpg",
      fav: false,
      angebot: true,
    },
    {
      name: "Pagodenstein",
      name2: "Tiefenwirkung",
      preis: 0,
      einheit: "4,50 /kg",
      bild: "pagode.jpg",
      fav: false,
    },
    {
      name: "Blauer-Sodalit",
      name2: "gerundet",
      preis: 0,
      einheit: "6 /kg",
      bild: "blauersodalit.jpg",
      fav: false,
    },
    {
      name: "Picturestone",
      name2: "faszinierend",
      preis: 0,
      einheit: "5,50 /kg",
      bild: "picture.jpg",
      fav: false,
    },
    {
      name: "Rosenquarz",
      name2: "Heilstein",
      preis: 0,
      einheit: "6 /kg",
      bild: "rosenquarz.jpg",
      fav: false,
    },
    {
      name: "Glimmer Wood Rock",
      name2: "glitzert",
      preis: 0,
      einheit: "5 /kg",
      bild: "glimmer.jpg",
      fav: false,
    },
    {
      name: "versteinertes Holz",
      name2: "braun-rot",
      preis: 0,
      einheit: "4,50 /kg",
      bild: "holz.jpg",
      fav: false,
    },
    {
      name: "versteinertes Holz",
      name2: "bunt",
      preis: 0,
      einheit: "4,50 /kg",
      bild: "holzbunt.jpg",
      fav: false,
    },
    {
      name: "Messerstein",
      name2: "kantig, schwarz",
      preis: 0,
      einheit: "4 /kg",
      bild: "messerstein.jpg",
      fav: false,
    },
    {
      name: "Helle Pagode",
      name2: "sehr schön",
      preis: 0,
      einheit: "6 /kg",
      bild: "hellepagode.jpg",
      fav: false,
    },
    {
      name: "verstreinertes Laub",
      name2: "rot-braun",
      preis: 0,
      einheit: "5,50 /kg",
      bild: "laub.jpg",
      fav: false,
    },
    {
      name: "Eisberg",
      name2: "strahlend weiß",
      preis: 0,
      einheit: "5 /kg",
      bild: "eisberg.jpg",
      fav: false,
    },
    {
      name: "Roter Amöbenstein",
      name2: "marmoriert",
      preis: 0,
      einheit: "5 /kg",
      bild: "roteramoebenstein.jpg",
      fav: false,
    },
    {
      name: "Sandwüstenstein",
      name2: "beige",
      preis: 0,
      einheit: "5,50 /kg",
      bild: "sandwuestenstein.jpg",
      fav: false,
    },
    {
      name: "Seegebirge",
      name2: "tolle Formen",
      preis: 0,
      einheit: "5 /kg",
      bild: "seegebirge.jpg",
      fav: false,
    },
    {
      name: "Schneckenstein",
      name2: "für Hobby Paläontologen",
      preis: 0,
      einheit: "5,50 /kg",
      bild: "schneckenstein.jpg",
      fav: false,
    },
    {
      name: "Purple Jade",
      name2: "jadegrüne Adern",
      preis: 0,
      einheit: "7 /kg",
      bild: "purplejade.jpg",
      fav: false,
    },
    {
      name: "Freak Rock",
      name2: "kräftige Farben",
      preis: 0,
      einheit: "6,50 /kg",
      bild: "freakrock.jpg",
      fav: false,
    },
    {
      name: "Streifenburgunder",
      name2: "roter Sandstein mit Quarzadern",
      preis: 0,
      einheit: "5 /kg",
      bild: "streifenburgunder.jpg",
      fav: false,
    },
    {
      name: "Schwarzer Fels",
      name2: "tief schwarz",
      preis: 0,
      einheit: "4 /kg",
      bild: "schwarzerfels.jpg",
      fav: false,
    },
    {
      name: "Galapagos-Stein",
      name2: "Für Schildkröten geeignet",
      preis: 0,
      einheit: "5 /kg",
      bild: "galapagos.jpg",
      fav: false,
    },
    {
      name: "Shodo-Stein",
      name2: "Wachteleier",
      preis: 0,
      einheit: "7 /kg",
      bild: "shodo.jpg",
      fav: false,
    },
    {
      name: "Tsingy-Stein",
      name2: "Jeder Stein ein Scape",
      preis: 0,
      einheit: "5,50 /kg",
      bild: "tsingy.jpg",
      fav: false,
    },
    {
      name: "Loa Rock",
      name2: "poröse Oberfläche",
      preis: 0,
      einheit: "5 /kg",
      bild: "loarock.jpg",
      fav: false,
    },
    {
      name: "Jurassic Reef-Rock",
      name2: "künstliche Koralle",
      preis: 0,
      einheit: "8 /kg",
      bild: "jurassic.jpg",
      fav: false,
    },
    {
      name: "Save-the-Reef-Rock",
      name2: "künstliche Koralle",
      preis: 0,
      einheit: "6 /kg",
      bild: "save.jpg",
      fav: false,
    },
    {
      name: "versteinertes Laub",
      name2: "hell-grün",
      preis: 0,
      einheit: "5,50 /kg",
      bild: "laubhell.jpg",
      fav: false,
    },
    {
      name: "Roter-Jaspis",
      name2: "gerundet",
      preis: 0,
      einheit: "6 /kg",
      bild: "roterjaspis.jpg",
      fav: false,
    },

    {
      name: "Schiefer",
      name2: "grau-schwarz",
      preis: 0,
      einheit: "4 /kg",
      bild: "schiefer.jpg",
      fav: false,
    },
    {
      name: "Regenbogenschiefer",
      name2: "bunt",
      preis: 0,
      einheit: "4 /kg",
      bild: "regenbogenschiefer.jpg",
      fav: false,
    },

    {
      name: "Wand-Stein",
      name2: "S",
      preis: 0,
      einheit: "10 ",
      bild: "wandstein.jpg",
      fav: false,
    },
    {
      name: "Wand-Stein",
      name2: "M",
      preis: 0,
      einheit: "15 ",
      bild: "wandstein.jpg",
      fav: false,
    },
    {
      name: "Wand-Stein",
      name2: "L",
      preis: 0,
      einheit: "25 ",
      bild: "wandstein.jpg",
      fav: false,
    },
  ],
};

function Steine({ setCurrentPage }) {
  window.scrollTo(0, 0);
  return (
    <div>
      <Menue />
      <h1>Unsere Steine</h1>
      <div className="produkt-container">
        {steine.steine.map((stein, index) => {
          return (
            <div key={index} className="produktkarte">
              <div>
                <h2>{stein.name}</h2>
                <h3>{stein.name2}</h3>
              </div>
              <div>
                <img
                  alt="stein"
                  src={
                    process.env.PUBLIC_URL +
                    "/pictures/steine/thumbs/" +
                    stein.bild
                  }
                ></img>
              </div>
            </div>
          );
        })}
      </div>

      <div style={{ textDecoration: "underline", marginTop: "20px" }}>
        <Link to="/" className="white">
          zurück &#x2B90;
        </Link>
      </div>
    </div>
  );
}

export default Steine;
