import Main from './Main';
import Footer from './Footer';
import { useState } from 'react';
import Steine from './Steine';
import Bonsai from './Bonsai';
import Wurzeln from './Wurzeln';
import Impressum from './Impressum';
import Datenschutz from './Datenschutz';
import Anfahrt from './Anfahrt';
import Cookie from './Cookie';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import More from './More';
import Header from './Header';
import Sale from './Sale';
import BodengrundRechner from './BodengrundRechner';
import Xd from './Xd';




function App() {



  const [cookie, setCookie] = useState(window.localStorage.getItem("cookie") === "true" ? true : false)
  const [banner, setBanner] = useState(false);









  window.scrollTo(0, 0);


  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Main banner={banner} setBanner={setBanner} />} />

          <Route
            path="/steine"
            element={<Steine />

            }
          />
          <Route
            path="/bonsai"
            element={<Bonsai />
            }
          />
          <Route
            path="/wurzeln"
            element={<Wurzeln />
            }
          />
          <Route
            path="/impressum"
            element={<Impressum />
            }
          />
          <Route
            path="/datenschutz"
            element={<Datenschutz />
            }
          />
          <Route
            path="/anfahrt"
            element={<Anfahrt />
            }
          />
          <Route
            path="/more"
            element={<More />
            }
          />
          <Route
            path="/restware"
            element={<Sale />
            }
          />

          <Route
            path="/ausverkauf"
            element={<Xd />
            }
          />

          <Route
            path="/bodengrundrechner"
            element={<BodengrundRechner />
            }
          />







        </Routes>
        <Footer />

        {cookie === false && <Cookie setCookie={setCookie} />}


        <div id="back" onClick={() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }}>

          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" /></svg>


        </div>

      </Router>




    </div>
  );
}

export default App;
