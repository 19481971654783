import { Link } from "react-router-dom";
import Menue from "./Menue";

export default function Anfahrt() {
  window.scrollTo(0, 0);
  return (
    <div className="">
      <Menue />
      <h2 className="">Anfahrt</h2>
      <div>
        <strong>Von der A46:</strong> Hückelhoven-Ost abfahren, Richtung
        Hückelhoven. <br></br>
        Die ganze Zeit der Straße folgen, bis zur Rurbrücke.<br></br> Hinter der
        Brücke links abbiegen, in die Marienstraße. <br></br>Folgen Sie der
        Kurve, bis zum großen Parkplatz <br></br>und dort finden Sie uns.
        <br></br>
        <br></br>
        <strong> Von Brachelen kommend:</strong> An der Gaststätte Windelen
        rechts in die Marienstraße fahren und<br></br> dann nur noch geradeaus,
        bis zum Parkplatz.
      </div>
      <img
        className="smaller-picture "
        src={process.env.PUBLIC_URL + "/pictures/parkplatz.jpg"}
        alt=""
      ></img>
      <div
        style={{
          textDecoration: "underline",
          marginTop: "20px",
          cursor: "pointer",
        }}
      >
        <Link to="/" className="white">
          zurück &#x2B90;
        </Link>
      </div>
    </div>
  );
}
