export default function Header() {
  return (
    <div className="top">
      <div className="openinghours">
        Öffnungszeiten: vorübergehend geschlossen
      </div>
      <div className="social-top">
        <a href="https://www.facebook.com/AquaZen.de">
          <img
            alt="facebook"
            src={process.env.PUBLIC_URL + "/pictures/facebook.png"}
          ></img>
        </a>
        <a href="https://www.instagram.com/aquazen.de/">
          {" "}
          <img
            alt="instagram"
            src={process.env.PUBLIC_URL + "/pictures/instagram.png"}
          ></img>
        </a>
      </div>{" "}
    </div>
  );
}

/* 
<a href="https://wa.me/491636804637">
{" "}
<img
  alt="whatsapp"
  src={process.env.PUBLIC_URL + "/pictures/whatsapp.png"}
></img>
</a> */
