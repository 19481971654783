import { Link } from "react-router-dom";

export default function Menue() {
  return (
    <div id="menue" className="menue">
      <Link to="/">Home </Link>

      <Link to="/more">Fotos</Link>
    </div>
  );
}

/* <Link to="/restware">Restware</Link> */
