import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <div className="footer">
        <div className="footer-left">
          {" "}
          <div className="footer-text">
            Öffnungszeiten
            <div>vorübergehend geschlossen</div>
            <a href="tel:024339031818">&#9743; 02433/903 18 18</a>
            <a href="mailto:info@aquazen.de">&#x2709; info@aquazen.de</a>
            <a
              href="https://www.google.de/maps/place/AquaZen,+Felix+Bitter+EU/@51.0399298,6.2196828,17z/data=!3m1!4b1!4m5!3m4!1s0x47c0a943251c2b5d:0x53f5bf07efa6fdec!8m2!3d51.0399298!4d6.2218768"
              rel="noreferrer"
              target="_blank"
            >
              Marienstraße 37-43 EG04<br></br>
              41836 Hückelhoven (Hilfarth)
            </a>
          </div>
        </div>

        <div className="footer-right footer-text">
          <div style={{ textDecoration: "underline", cursor: "pointer" }}>
            <Link to="/bodengrundrechner">Bodengrundrechner</Link>
          </div>

          <div style={{ textDecoration: "underline", cursor: "pointer" }}>
            <Link to="/datenschutz">Datenschutzerklärung</Link>
          </div>
          <div style={{ textDecoration: "underline", cursor: "pointer" }}>
            <Link to="/impressum"> Impressum</Link>
          </div>

          <div className="social" style={{ marginTop: "15px" }}>
            <a href="https://www.facebook.com/AquaZen.de">
              <img
                alt="facebook"
                src={process.env.PUBLIC_URL + "/pictures/facebook.png"}
              ></img>
            </a>
            <a href="https://www.instagram.com/aquazen.de/">
              <img
                alt="instagram"
                src={process.env.PUBLIC_URL + "/pictures/instagram.png"}
              ></img>
            </a>
          </div>
        </div>
      </div>

      <div
        style={{
          paddingBottom: "25px",
          paddingLeft: "15px",
          paddingRight: "15px",
        }}
      >
        Wir akzeptieren alle üblichen Zahlungsmittel: Apple Pay, Google Pay,
        Samsung Pay, PayPal, Kreditkarten, Bankkarten und Bargeld.
      </div>
    </>
  );
}
