import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import Menue from "./Menue";
import { useEffect } from "react";
import ReviewElement from "./ReviewElement";

export default function Main({ banner, setBanner }) {
  useEffect(() => {
    if (banner) {
      window.setTimeout(() => {
        setBanner(false);
      }, 5000);
    }
    try {
      window.document.getElementById("back").style.display = "none";
    } catch {
      console.log("no back button");
    }

    return () => {
      window.clearTimeout();
      try {
        window.document.getElementById("back").style.display = "flex";
      } catch {
        console.log("no back button");
      }
    };
  }, []);

  function Banner() {
    /*   return (
      <div
        className="banner"
        onClick={() => {
          setBanner(false);
        }}
      ></div>
    ); */

    return null;
  }
  return (
    <div key={uuidv4()}>
      <div className="title-container">
        <img
          alt="title"
          className="title-image"
          src={process.env.PUBLIC_URL + "/pictures/title.png"}
        />
        <div className="logo-container">
          <img
            alt="logo"
            className="logo-image"
            src={process.env.PUBLIC_URL + "/pictures/logo.png"}
          />
        </div>
      </div>
      <Menue />

      <div className="quote-element">
        <h2>ACHTUNG, WIR ZIEHEN UM UND HABEN GESCHLOSSEN</h2>
      </div>
      <div>
        <h1>Aquaristik, Aquascaping und mehr ...</h1>
      </div>

      <div className="main-3-images">
        <div className="img-container-text">
          <img
            alt="gem"
            src={process.env.PUBLIC_URL + "/pictures/gemuetlich.jpg"}
          />
          <p>
            {" "}
            Jedes Naturprodukt ist ein Einzelstück, deswegen ist es so wichtig
            sich diese persönlich ansehen zu können. Bei uns kannst du jedes
            Teil von allen Seiten betrachten ...
          </p>
        </div>
        <div className="img-container-text">
          <img
            alt="sca"
            src={process.env.PUBLIC_URL + "/pictures/scapetisch.jpg"}
          />
          <p>
            ... und an unserem Scapetisch verschiedene Designs testen und gerne
            für den Nachbau zu Hause fotografieren. Wir beraten euch gerne.
          </p>
        </div>
        <div className="img-container-text">
          <img
            alt="par"
            src={process.env.PUBLIC_URL + "/pictures/parkplatz.jpg"}
          />
          <p>
            Bei uns gibt es immer genug freie Parkplätze. <br />
            Marienstraße 43 in 41836 Hückelhoven-Hilfarth
          </p>
        </div>
      </div>
      <div className="quote-element">
        <h2>Hierauf haben wir uns spezialisiert</h2>
        <p>
          Bei uns bekommst du eine große Auswahl an Hardscape. Mehr als 30
          verschiedene Steinarten und Wurzeln, viele Bonsai, unterschiedlichste
          Bodengründe sowie ausgefallene Pflanzen. <br />
          <br /> Wir bieten viele Naturprodukte zur Dekoration und als
          Unterschlupf für Garnelen und Fische. Eine große Auswahl an Glas- und
          Edelstahl- Ein-/Ausläufen und CO2-Diffusoren haben wir auch im
          Sortiment. Natürlich gibt es bei uns auch Aquarien, Futter, Technik,
          Wasserpflege und Zubehör.
        </p>
        <div className="small-image-container">
          <img
            src={process.env.PUBLIC_URL + "/pictures/bonsaic.jpg"}
            alt="bonsai"
          />
          <img
            src={process.env.PUBLIC_URL + "/pictures/steinec.jpg"}
            alt="steine"
          />
          <img
            src={process.env.PUBLIC_URL + "/pictures/zubehoerc.jpg"}
            alt="zubehoer"
          />
        </div>
        <p style={{ textDecoration: "underline", cursor: "pointer" }}>
          <Link className="sc" to="/more">
            Mehr erfahren ...
          </Link>
        </p>
      </div>
      <div className="text">
        <h2>Qualität zu günstigen Preisen</h2>
        <p>
          Wir legen großen Wert auf Qualität. Bei uns findest du ausschließlich
          Produkte, die wir selbst verwenden würden. Durch unseren Standort und
          unsere Öffnungszeiten haben wir geringe Betriebskosten. Diese
          Ersparnis geben wir an euch weiter und so bekommt ihr bei uns Qualität
          zu guten Preisen.
        </p>
      </div>
      <div className="">
        <picture tabIndex="-1" className="">
          <img
            style={{ borderRadius: "15px" }}
            src={process.env.PUBLIC_URL + "/pictures/ninafelix.jpg"}
            className="rdli"
            alt="rdli"
          ></img>
        </picture>
        <div className="w80">
          <h2 className="">Du willst online in unseren Laden gucken? </h2>
          Der YOUTUBER &quot;Aqua one&quot; hat uns besucht. Auf seinem YOUTUBE
          Kanal kannst du einen Rundgang durch unseren Laden machen.
          <br></br>
        </div>
        <div className="w80">
          Einfach unten auf den Link klicken und du wirst zum Anbieter YOUTUBE
          weitergeleitet.
        </div>

        <div>
          {" "}
          <a
            href="https://youtu.be/RkV3ZxZpYDk"
            target={"blank"}
            className=""
            style={{
              textDecoration: "underline",
              color: "white",
            }}
          >
            <br />
            Rundgang durch den Laden ...
          </a>
        </div>
      </div>

      <div className="quote-element">
        <h2>ACHTUNG, WIR ZIEHEN UM UND HABEN GESCHLOSSEN</h2>
      </div>
      <h2 className="w80">Unsere Kunden sind begeistert</h2>
      <ReviewElement />
      {banner === true && <Banner />}
    </div>
  );
}
