const bonsai = {
    bonsai: [
        {
            name: "Octopus-Holz",
            name2: "S, h:15-18 cm, l:20-22 cm",
            preis: 0,
            einheit: "40 ",
            bild: "octopusholz.jpg",
            fav: false,
        },
        {
            name: "Octopus-Holz",
            name2: "M, h:18-25 cm, l:30-35 cm",
            preis: 0,
            einheit: "65 ",
            bild: "octopusholz.jpg",
            fav: false,
        },
        {
            name: "Octopus-Holz",
            name2: " L, h:30-35 cm, l:37-40 cm ",
            preis: 0,
            einheit: "110 ",
            bild: "octopusholz.jpg",
            fav: false,
        },
        {
            name: "Claw- Bonsai",
            name2: " H: 18 cm, L: 25 cm ",
            preis: 0,
            einheit: "105",
            bild: "platzhalter.png",
            fav: false,
        },
        {
            name: "Claw- Bonsai",
            name2: " H: 25 cm, L: 40 cm ",
            preis: 0,
            einheit: "65",
            bild: "platzhalter.png",
            fav: false,
        },
        {
            name: "Mammut-Bonsai",
            name2: " S, H: 15cm, L: 20 cm ",
            preis: 0,
            einheit: "45",
            bild: "mammut.jpg",
            fav: false,
        },
        {
            name: "Mammut-Bonsai",
            name2: " M, H: 20cm, L: 25 cm ",
            preis: 0,
            einheit: "75",
            bild: "mammut.jpg",
            fav: false,
        },
        {
            name: "Mammut-Bonsai",
            name2: " L, H: 30cm, L: 35 cm ",
            preis: 0,
            einheit: "125",
            bild: "mammut.jpg",
            fav: false,
        },
        {
            name: "Drachen-Bonsai",
            name2: " S, H: 15-18 cm, L: 20-22 cm ",
            preis: 0,
            einheit: "60",
            bild: "drachenbonsai.jpg",
            fav: false,
        },
        {
            name: "Drachen-Bonsai",
            name2: "M, H: 18-25 cm, L: 28-30 cm ",
            preis: 0,
            einheit: "85",
            bild: "drachenbonsai.jpg",
            fav: false,
        },
        {
            name: "Drachen-Bonsai",
            name2: " L, H: 30-35 cm, L: 37-40 cm ",
            preis: 0,
            einheit: "140",
            bild: "drachenbonsai.jpg",
            fav: false,
        },
        {
            name: "Aqua-Bonsai",
            name2: " S, H:15 cm, L:20cm ",
            preis: 0,
            einheit: "50",
            bild: "platzhalter.png",
            fav: false,
        },
        {
            name: "Aqua-Bonsai",
            name2: " M, H:23 cm, L:30cm ",
            preis: 0,
            einheit: "75",
            bild: "platzhalter.png",
            fav: false,
        },
        {
            name: "Aqua-Bonsai",
            name2: " L, H:30 cm, L:40cm ",
            preis: 0,
            einheit: "125",
            bild: "platzhalter.png",
            fav: false,
        },
        {
            name: "Aqua-Bonsai",
            name2: "XL, H:50 cm, L:70 cm ",
            preis: 0,
            einheit: "290",
            bild: "aquabonsai.jpg",
            fav: false,
        },
        {
            name: "Wald-Bonsai",
            name2: "S, L:30 cm, B:26 cm, H:20 cm ",
            preis: 0,
            einheit: "85",
            bild: "platzhalter.png",
            fav: false,
        },
        {
            name: "Wald-Bonsai",
            name2: "M, L:40 cm, B:20 cm, H: 25 cm ",
            preis: 0,
            einheit: "140",
            bild: "platzhalter.png",
            fav: false,
        },
        {
            name: "Wald-Bonsai",
            name2: "L, L:50 cm, B:25 cm, H:30 cm ",
            preis: 0,
            einheit: "175",
            bild: "platzhalter.png",
            fav: false,
        },
        {
            name: "Layout Bridge",
            name2: "S, l: 40 cm, h: 30 cm ",
            preis: 0,
            einheit: "",
            bild: "platzhalter.png",
            fav: false,
        },
        {
            name: "Layout Bridge",
            name2: "M, l: 60 cm, h: 40 cm ",
            preis: 0,
            einheit: "",
            bild: "platzhalter.png",
            fav: false,
        },
        {
            name: "Layout Bridge",
            name2: "L, l: 80 cm, h: 45 cm ",
            preis: 0,
            einheit: "290",
            bild: "platzhalter.png",
            fav: false,
        },
        {
            name: "Layout Cave",
            name2: "S, l: 40 cm, h: 30 cm ",
            preis: 0,
            einheit: "70",
            bild: "layoutcave.jpg",
            fav: false,
        },
        {
            name: "Layout Cave",
            name2: "L, l: 60 cm, h: 40 cm ",
            preis: 0,
            einheit: "325",
            bild: "layoutcavel.jpg",
            fav: false,
        },
        {
            name: "Layout Forest",
            name2: "S, l: 40cm, h: 30 cm ",
            preis: 0,
            einheit: "230",
            bild: "forrest.jpg",
            fav: false,
        },
        {
            name: "Layout Forest",
            name2: "L, l: 60 cm, h: 40 cm ",
            preis: 0,
            einheit: "290",
            bild: "forrest.jpg",
            fav: false,
        },
        {
            name: "Layout Forest",
            name2: "XXL, l: 80 cm, h: 45 cm ",
            preis: 0,
            einheit: "",
            bild: "forrest.jpg",
            fav: false,
        },
        {
            name: "Layout Jungle",
            name2: "M, l: 60 cm, h: 40 cm ",
            preis: 0,
            einheit: "400",
            bild: "platzhalter.png",
            fav: false,
        },
        {
            name: "Layout Jungle",
            name2: "XL, l: 120 cm, h: 45 cm ",
            preis: 0,
            einheit: "",
            bild: "platzhalter.png",
            fav: false,
        },
        {
            name: "Layout Flying Bridge",
            name2: "S ",
            preis: 0,
            einheit: "185",
            bild: "flyingbridge.jpg",
            fav: false,
        },
        {
            name: "Layout Flying Bridge",
            name2: "L ",
            preis: 0,
            einheit: "255",
            bild: "platzhalter.png",
            fav: false,
        },
        {
            name: "Planter Bonsai",
            name2: "M ",
            preis: 0,
            einheit: "50",
            bild: "planterbonsai.jpg",
            fav: false,
        },
        {
            name: "Planter Bonsai",
            name2: "L ",
            preis: 0,
            einheit: "60",
            bild: "platzhalter.png",
            fav: false,
        },
    ],
};

export default bonsai;