import React, { useEffect, useState } from "react";
import Menue from "./Menue";

export default function BodengrundRechner(props) {
  const [breite, setBreite] = useState(20);
  const [tiefe, setTiefe] = useState(20);
  const [hoehe, setHoehe] = useState(5);
  const [material, setMaterial] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dichte = [625, 1381, 692, 666, 715];
  const bg = ["Sand", "Manado", "Volcano", "KiesF", "KiesG"];

  const volumen = (breite * tiefe * hoehe) / 1000;
  const kg = ((breite * tiefe * hoehe) / dichte[material]).toFixed(2);

  const breiteref = [20, 40, 60, 80, 100, 120];
  const tieferef = [10, 20, 30, 40, 50, 60];

  return (
    <>
      <Menue />

      <div className="calccontainer">
        <div className="calc">
          <div className="calctop">
            <h1>Bodengrundrechner</h1>
          </div>

          <div className="calcbottom">
            <h2> Wählen Sie den Bodengrund aus</h2>
            <div className="choosecalc">
              <div
                className={material === 0 ? "selected" : ""}
                onClick={() => {
                  setMaterial(0);
                }}
              >
                Sand
              </div>
              <div
                className={material === 1 ? "selected" : ""}
                onClick={() => {
                  setMaterial(1);
                }}
              >
                Manado
              </div>
              <div
                className={material === 2 ? "selected" : ""}
                onClick={() => {
                  setMaterial(2);
                }}
              >
                Volcano grob
              </div>
              <div
                className={material === 3 ? "selected" : ""}
                onClick={() => {
                  setMaterial(3);
                }}
              >
                feiner Kies
              </div>
              <div
                className={material === 4 ? "selected" : ""}
                onClick={() => {
                  setMaterial(4);
                }}
              >
                grober Kies
              </div>
            </div>
            <h2>Wir breit ist das Aquarium?</h2>
            <div className="choosecalc">
              <div
                className={breite === 20 ? "selected" : ""}
                onClick={() => {
                  setBreite(20);
                }}
              >
                20
              </div>
              <div
                className={breite === 40 ? "selected" : ""}
                onClick={() => {
                  setBreite(40);
                }}
              >
                40
              </div>
              <div
                className={breite === 60 ? "selected" : ""}
                onClick={() => {
                  setBreite(60);
                }}
              >
                60
              </div>
              <div
                className={breite === 80 ? "selected" : ""}
                onClick={() => {
                  setBreite(80);
                }}
              >
                80
              </div>
              <div
                className={breite === 100 ? "selected" : ""}
                onClick={() => {
                  setBreite(100);
                }}
              >
                100
              </div>
              <div
                className={breite === 120 ? "selected" : ""}
                onClick={() => {
                  setBreite(120);
                }}
              >
                120
              </div>
              <div
                className={
                  "calc-input " +
                  (!breiteref.includes(breite) ? "selected" : "")
                }
              >
                <label>freie Eingabe:</label>
                <input
                  className="calcinputfield"
                  type="text"
                  placeholder="in cm"
                  style={{
                    width: "50px",
                    textAlign: "right",
                    marginLeft: "5px",
                  }}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      return;
                    }
                    if (e.target.value > 0) {
                      setBreite(e.target.value);
                    }
                    if (isNaN(e.target.value)) {
                      alert("Bitte geben Sie eine Zahl ein");
                    }
                  }}
                ></input>
              </div>
            </div>
            <h2> Wie tief ist das Aquarium?</h2>
            <div className="choosecalc">
              <div
                className={tiefe === 10 ? "selected" : ""}
                onClick={() => {
                  setTiefe(10);
                }}
              >
                10
              </div>
              <div
                className={tiefe === 20 ? "selected" : ""}
                onClick={() => {
                  setTiefe(20);
                }}
              >
                20
              </div>
              <div
                className={tiefe === 30 ? "selected" : ""}
                onClick={() => {
                  setTiefe(30);
                }}
              >
                30
              </div>
              <div
                className={tiefe === 40 ? "selected" : ""}
                onClick={() => {
                  setTiefe(40);
                }}
              >
                40
              </div>
              <div
                className={tiefe === 50 ? "selected" : ""}
                onClick={() => {
                  setTiefe(50);
                }}
              >
                50
              </div>
              <div
                className={tiefe === 60 ? "selected" : ""}
                onClick={() => {
                  setTiefe(60);
                }}
              >
                60
              </div>
              <div
                className={
                  "calc-input " + (!tieferef.includes(tiefe) ? "selected" : "")
                }
              >
                <label>freie Eingabe:</label>
                <input
                  type="text"
                  placeholder="in cm"
                  style={{
                    width: "50px",
                    textAlign: "right",
                    marginLeft: "5px",
                  }}
                  className="calcinputfield"
                  onChange={(e) => {
                    if (e.target.value === "") {
                      return;
                    }
                    if (e.target.value > 0) {
                      setTiefe(e.target.value);
                    }
                    if (isNaN(e.target.value)) {
                      alert("Bitte geben Sie eine Zahl ein");
                    }
                  }}
                ></input>
              </div>
            </div>
            <h2>Wie hoch soll der Bodengrund sein?</h2>
            <p>
              <div style={{ marginBottom: "5px" }}>{hoehe}cm</div>
              <input
                type="range"
                min="1"
                max="10"
                value={hoehe}
                className="sliderhoehe"
                id="myrange"
                onInput={(e) => {
                  const value = e.target.value;
                  setHoehe(value);
                }}
              />
            </p>
          </div>
        </div>
        <div className="calctop">
          <div className="flexrow">
            <div>
              <strong>Abmessungen des Aquariums:</strong>{" "}
            </div>
            <div>
              B: {breite}cm / T: {tiefe}cm / H: {hoehe}cm
            </div>
          </div>

          <div className="flexrow">
            <div>
              <strong>Bodengrund:</strong>
            </div>
            <div>{bg[material]} </div>
          </div>

          <div className="erg">
            <div>Entspricht</div>
            <div>{volumen?.toString().replace(".", ",")} L</div>
            <div>&#8776; {kg?.toString().replace(".", ",")} kg</div>
          </div>
        </div>
      </div>
    </>
  );
}
