import React from "react";
import { Link } from "react-router-dom";
import bonsai from "./bonsai-daten.js";
import Menue from "./Menue";

function Bonsai() {
  return (
    <div>
      <Menue />
      <h1>Unsere Bonsai</h1>
      <div className="produkt-container">
        {bonsai.bonsai.map((bonsai, index) => {
          return (
            <div key={index} className="produktkarte">
              <div>
                <h2>{bonsai.name}</h2>
                <h3>{bonsai.name2}</h3>
              </div>
              <div>
                <img
                  alt={bonsai.name}
                  src={
                    process.env.PUBLIC_URL +
                    "/pictures/bonsai/thumbs/" +
                    bonsai.bild
                  }
                ></img>
              </div>
            </div>
          );
        })}
      </div>

      <div style={{ textDecoration: "underline", marginTop: "20px" }}>
        <Link to="/" className="white">
          zurück &#x2B90;
        </Link>
      </div>
    </div>
  );
}

export default Bonsai;
