import { useRef, useEffect } from "react";
import Menue from "./Menue";

import Restware from "./Restware";

export default function Sale() {
  const myvideo = useRef();

  window.scrollTo(0, 0);

  useEffect(() => {
    let hasStarted = false; // Eine Zustandsvariable, um zu überprüfen, ob das Video bereits gestartet wurde

    const playVideo = () => {
      if (hasStarted) return; // Verhindere mehrfache Abspielversuche
      hasStarted = true;
      myvideo.current.play().catch((error) => {
        console.error(
          "Video konnte nicht automatisch abgespielt werden:",
          error
        );
      });
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Nur versuchen, das Video abzuspielen, wenn genug Daten geladen wurden
            if (myvideo.current.readyState >= 2) {
              playVideo();
            } else {
              // Füge einen EventListener hinzu, der das Video abspielt, sobald es spielbar ist
              myvideo.current.addEventListener("canplay", playVideo);
            }
          } else {
            hasStarted = false; // Setze den Zustand zurück, wenn das Video nicht sichtbar ist
            myvideo.current.pause();
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the video is visible
    );

    if (myvideo.current) {
      observer.observe(myvideo.current);
    }

    // Cleanup function
    return () => {
      if (myvideo.current) {
        observer.unobserve(myvideo.current);
        myvideo.current.removeEventListener("canplay", playVideo); // Entferne EventListener beim Cleanup
      }
    };
  }, []);

  return (
    <div>
      <Menue />
      <h1></h1>

      <div className="quote-element">
        <h2>ACHTUNG WIR ZIEHEN UM</h2>

        <h3>Wir haben noch Ware übrig.</h3>
        <p>
          Ein Versand ist möglich. <br /> <br />
          <br />
        </p>
      </div>

      <div>
        <h2>Das ist noch da</h2>
        <h3>Hier eine Übersicht, was aktuell noch vorrätig ist</h3>

        <div>
          Fotos vom <strong>14.05.2024</strong>
        </div>

        <div className="nochda">
          <Restware />
        </div>
        <div className="click">
          (Für eine größere Ansicht einfach auf ein Bild klicken)
        </div>
      </div>
      <div className="futter">
        <h2>Wir haben Spezialfutter für</h2>
        <div className="container-flex">
          <img
            className="futter-bild"
            src={process.env.PUBLIC_URL + "/pictures/futter.jpg"}
          />
          <ul>
            <li>Garnelen</li>
            <li>Chichliden</li>
            <li> Malawi</li>
            <li>Krebse</li>
            <li> Schnecken</li>
            <li>Vampirkrabben</li>
            <li> Wasserschildkröten</li>
            <li> Axolotl</li>
            <li> Koi</li>

            <li> Teichfische</li>
            <li> Diskus</li>

            <li> Welse</li>
          </ul>
        </div>
        <img
          className="futter-bild-2"
          src={process.env.PUBLIC_URL + "/pictures/garnelen.jpg"}
        />
      </div>
      <div className="quote-element mw">
        <h2>Premium-Futter von Hikari</h2>
        <div>
          Hikari Cichlid Staple ist ein tägliches Futter für schnelles Wachstum
          und daher auch für größere Arten geeignet. Das treibende Granulat ist
          reich an Vitamin C und bietet genau die Nährstoffe, die den Fischen
          Wohlbefinden und Gesundheit schenken. <br />
          Tägliches Wachstumsfutter für Fleischfressende Cichliden.
          <br /> Stimuliert ein fantastisches Wachstum ohne Fettansammlungen.{" "}
          <br />
          Schnelle Akzeptanz durch einen angenehmen Geschmack für Ihre Cichliden
        </div>
        <div className="quote-pictures">
          <img src={process.env.PUBLIC_URL + "/pictures/hc1.png"} />
          <img src={process.env.PUBLIC_URL + "/pictures/hc2.png"} />
          <img src={process.env.PUBLIC_URL + "/pictures/hc3.png"} />
        </div>
      </div>
    </div>
  );
}

{
  /* <div className="video-container">
<video
  controls
  ref={myvideo}
  muted
  playsInline
  poster={process.env.PUBLIC_URL + "/pictures/vorschaubildvideo.jpg"}
>
  <source
    src={"https://aquazen.de/pictures/05032024.mp4"}
    type="video/mp4"
  />
</video>
<div className="date">Stand: 24.03.2024</div>
</div> */
}
