import React from "react";
import { Link } from "react-router-dom";
import Menue from "./Menue";

const wurzel = {
  wurzeln: [
    {
      name: "Savannenholz",
      name2: "sandgestralt",
      preis: 0,
      einheit: "Stück",
      bild: "savanne.jpg",
      fav: false,
    },
    {
      name: "Mopani Holz",
      name2: "Asia Mopani",
      preis: 0,
      einheit: "kg",
      bild: "asiamopani.jpg",
      fav: false,
    },
    {
      name: "Rote Moorwurzel",
      name2: "mini",
      preis: 0,
      einheit: "Stück",
      bild: "moormini.jpg",
      fav: false,
    },
    {
      name: "Rote Moorwurzel",
      name2: "bis 80cm",
      preis: 0,
      einheit: "kg",
      bild: "moor.jpg",
      fav: false,
    },
    {
      name: "Wood Stumps",
      name2: "sehr groß",
      preis: 0,
      einheit: "14 /kg",
      bild: "woodstumps.jpg",
      fav: false,
    },
    {
      name: "Curl Wood",
      name2: "filigran",
      preis: 0,
      einheit: "20 /kg",
      bild: "curlwood.jpg",
      fav: false,
    },
    {
      name: "Flusswurzel",
      name2: "braun",
      preis: 0,
      einheit: "18 /kg",
      bild: "flusswurzel.jpg",
      fav: false,
    },
    {
      name: "Talawa-Holz",
      name2: "für Chichliden",
      preis: 0,
      einheit: "22,50 /kg",
      bild: "talawa.jpg",
      fav: false,
    },
    {
      name: "Drachenholz",
      name2: "viele Hohlräume",
      preis: 0,
      einheit: "20,50 /kg",
      bild: "drachen.jpg",
      fav: false,
    },
    {
      name: "Mangrovenwurzel XL",
      name2: "70-80 cm, sandgestrahlt",
      preis: 0,
      einheit: "60 ",
      bild: "mangrove.jpg",
      fav: false,
    },
    {
      name: "Etosha-Tree",
      name2: "S",
      preis: 0,
      einheit: "12 ",
      bild: "etoshatree.jpg",
      fav: false,
    },
    {
      name: "Ethosha-Tree",
      name2: "M",
      preis: 0,
      einheit: "16 ",
      bild: "etoshatree2.jpg",
      fav: false,
    },
    {
      name: "Kongo-Baum",
      name2: "ausgezeichnet",
      preis: 0,
      einheit: "8 ",
      bild: "kongobaum.jpg",
      fav: false,
    },
    {
      mehrFotosSpeichern: [],
      kategorie: "wurzeln",
      bild: "68846257920603_290737816301702_5112826840836100277_n.jpg",
      name: "Black Slim Wood",
      preis: 0,
      einheit: "kg",
      name2: "schön gedreht",
      fav: false,
    },
  ],
};

function Wurzeln({ setCurrentPage }) {
  window.scrollTo(0, 0);
  return (
    <div>
      <Menue />
      <h1>Unsere Wurzeln</h1>
      <div className="produkt-container">
        {wurzel.wurzeln.map((wurzel, index) => {
          return (
            <div key={index} className="produktkarte">
              <div>
                <h2>{wurzel.name}</h2>
                <h3>{wurzel.name2}</h3>
              </div>
              <div>
                <img
                  alt="wurzel"
                  src={
                    process.env.PUBLIC_URL +
                    "/pictures/wurzeln/thumbs/" +
                    wurzel.bild
                  }
                ></img>
              </div>
            </div>
          );
        })}
      </div>

      <div style={{ textDecoration: "underline", marginTop: "20px" }}>
        <Link to="/" className="white">
          zurück &#x2B90;
        </Link>
      </div>
    </div>
  );
}

export default Wurzeln;
