import reviews from "./reviews.js";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";
export default function ReviewElement() {
  const [currentReview, setCurrentReview] = useState([]);
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

  useEffect(() => {
    const showReviews = () => {
      const r = [];
      for (let i = currentReviewIndex; i < reviews.length; i++) {
        if (r.length > 2) break;
        r.push(
          <div key={uuidv4()} className="review">
            <h2 className="reviewer-name">{reviews[i].name}</h2>
            <p title={reviews[i].review} className="reviewer-review">
              {reviews[i].review}
            </p>
            <div className="stars-container">{getStars(reviews[i].stars)}</div>
          </div>
        );
      }
      if (r.length < 3) {
        for (let i = 0; i < 3 - r.length; i++) {
          r.push(
            <div key={uuidv4()} className="review">
              <h2 className="reviewer-name">{reviews[i].name}</h2>
              <p title={reviews[i].review} className="reviewer-review">
                {reviews[i].review}
              </p>
              <div className="stars-container">
                {getStars(reviews[i].stars)}
              </div>
            </div>
          );
        }
      }

      setCurrentReview(r);
    };
    showReviews();
  }, [currentReviewIndex]);

  const getStars = (number) => {
    if (isNaN(number)) return console.log("not a number");
    number = parseInt(number);
    if (number > 5) number = 5;
    let stars = [];
    for (let i = 0; i < number; i++) {
      stars.push(
        <svg
          className="star"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 194.22 184.73"
        >
          <path
            fill="#fc0"
            d="m157.09624313 184.71807481-60.01047609-31.56494446-60.02437133 31.54175224 11.4755592-66.82748987L-.00931154 70.52751534l67.1029075-9.73708948L97.11501258-.00816438l29.9970934 60.81131153 67.0986995 9.76367105-48.56377264 47.3191202z"
          />
          <path
            fill="#ffe680"
            d="M127.315 60.416c-30.39 41.369-30.72 41.819-30.72 41.819l97.411-31.899z"
          />
          <path
            fill="#fd5"
            d="M97.095 101.346v51.942l-60.63 31.117zm0 0 59.613 81.476-11.189-65.984z"
          />
          <path fill="#ffe680" d="M.385 70.406 97.1 101.348 67.218 60.506z" />
          <path fill="#fd5" d="M97.095 101.346V.126l29.83 60.357z" />
          <path
            fill="#ffd42a"
            d="m37.085 183.566 11.261-66.541 48.757-15.679z"
          />
        </svg>
      );
    }
    return stars;
  };
  return (
    <div className="reviews-container">
      <div
        className="arrow-left"
        onClick={() => {
          if (currentReviewIndex > 0) {
            setCurrentReviewIndex(currentReviewIndex - 1);
          } else {
            setCurrentReviewIndex(reviews.length - 1);
          }
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
        </svg>
      </div>
      {currentReview}
      <div
        className="arrow-right"
        onClick={() => {
          if (currentReviewIndex < reviews.length - 1) {
            setCurrentReviewIndex(currentReviewIndex + 1);
          } else {
            setCurrentReviewIndex(0);
          }
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
        </svg>
      </div>
    </div>
  );
}
